.body-container {
  padding: 25px;
  margin: 25px;
}

.active-care-header {
  font-size: 24px;
  padding-left: 10px;
}

.active-care-sub-header {
  font-size: 18px;
}

.card-patient-events {
  margin-top: 25px;
  background-color: white;
}

.card-patient-events-header {
  font-size: 14px;
  color: grey;
  background-color: white;
}

.card-patient-events-badge {
  margin-left: 5px;
}

.table th {
  color: grey;
  font-size: 12px;
  font-weight: normal;
}

.page {
  position: relative;
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 250px;
  height: 100vh;
  position: sticky;
  top: 0;
  /* padding: 25px; */
}

.main {
  padding-top:25px;
  flex: 1;
  background-color: #F5F5F5;
  border-left: solid 1px #ECECEC;
}

.main > div {
  padding-left: 2rem !important;
  padding-right: 1.5rem !important;
}
